@font-face {
    font-family: 'EuropeExt';
    src: url('../../fonts/gutterprotect/europeext_bold.woff') format('woff2');
    font-weight: bold;
}

.naturelawncare-inner {
    font-family: "Ubuntu", sans-serif;
}

.gp__header-home {
    padding: 21px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #FFFFFF;
    border-bottom: 1px solid #E2E2E2;

    .logo {
        max-width: 290px;

        @media screen and (max-width: 768px) {
            max-width: 218px;
        }

        @media screen and (max-width: 520px) {
            max-width: 192px;
        }

        @media screen and (max-width: 479px) {
            max-width: 156px;
        }
    }
}

.naturelawncare-footer {
    background: #333333;

    a:hover {
        text-decoration: none !important;
    }

    .container {
        padding: 40px 20px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        @media (max-width: 800px) {
            flex-direction: column;
            align-items: center;
            justify-content: center;
            padding: 25px 15px;
        }
    }

    .footer__inner_menu {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-grow: 1;

        @media (max-width: 800px) {
            width: 100%;
            padding-bottom: 15px;
        }

        @media (max-width: 400px) {
            flex-direction: column;
        }

        li {
            margin-bottom: 0;
            padding: 0 20px;

            @media (max-width: 800px) {
                justify-content: center;
                display: flex;
            }

            @media (max-width: 400px) {
                padding: 20px;
            }
        }
    }

    &-rights {
        display: flex;
        align-items: center;
        gap: 13px;
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
        color: #A4A4A4;

        img {
            width: 36px;
            height: 36px;
        }

    }

    &-logo {
        max-width: 225px;

        @media (max-width: 800px) {
            padding-bottom: 15px;
        }

        @media (max-width: 479px) {
            max-width: 160px;
        }

        img {
            width: 100%;
        }
    }
}


.has-phone-btn {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .logo-icon {
        max-width: 200px;

        @media screen and (max-width: 480px) {
            max-width: 150px;
        }

        @media screen and (max-width: 360px) {
            max-width: 139px;
        }
    }

    .phone-link {
        width: 100%;
        max-width: 255px;
        padding: 12px 0px;
        height: 49px;
        display: flex;
        justify-content: center;
        align-items: center;
        column-gap: 10px;
        border-radius: 130px;
        background: #3787FF;
        transition: opacity 0.2s ease;
        text-decoration: none;

        &:hover {
            opacity: 0.8;
        }

        span {
            color: #FFF;
            text-align: center;
            font-size: 19px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            text-transform: uppercase;
        }

        svg {
            width: 18px;
            height: 31px;
        }

        @media screen and (max-width: 1000px) {
            max-width: 210px;
        }

        @media screen and (max-width: 768px) {
            max-width: 195px;
            column-gap: 8px;

            svg {
                width: 12.694px;
                height: 21.861px;
            }
        }

        @media screen and (max-width: 480px) {
            max-width: 155px;
            height: 34px;

            span {
                font-size: 14px;
            }

            svg {
                width: 8.89px;
                height: 15.31px;
            }
        }

        @media screen and (max-width: 360px) {
            max-width: 120px;
            height: 30px;

            span {
                font-size: 12px;
            }
        }
    }
}

.gp__main-home {
    font-family: "Ubuntu", sans-serif;
    color: #1a1a1a;

    .container {
        max-width: 1282px;
        padding: 0 20px;
    }

    p {
        margin: 0;
    }

    .h2 {
        margin: 0;
        font-size: 46px;
        font-weight: 700;
        line-height: 55.2px;
        color: #464646;

        span {
            color: #85C32F;
        }

        @media screen and (max-width: 1000px) {
            font-size: 42px;
            line-height: 50px;
        }

        @media screen and (max-width: 768px) {
            font-size: 32px;
            line-height: 38px;
        }

        @media screen and (max-width: 520px) {
            font-size: 26px;
            line-height: 31px;
        }

        @media screen and (max-width: 479px) {
            font-size: 24px;
            line-height: 28px;
        }
    }

    .h5 {
        margin: 0;
        font-weight: 700;
        font-size: 27px;
        line-height: 125%;

        @media screen and (max-width:480px) {
            font-size: 24px;
            line-height: 30px;
        }
    }

    .decoration-text-box {
        text-align: center;
    }

    .decoration-text {
        display: inline-block;
        margin: 10px 0 40px 0;
        padding: 10px 30px;
        font-weight: 500;
        font-size: 24px;
        line-height: 24px;
        background: #f2f2f2;
        border: 1px solid #CBDAFF;
        border-radius: 30px;

        span {
            color: #30a31a;
            text-decoration: underline;
        }

        @media screen and (max-width: 768px) {
            margin-top: 18px;
            font-size: 18px;
        }

        @media screen and (max-width: 479px) {
            margin-top: 15px;
            font-size: 15px;
        }
    }

    .videoBlock {
        display: block;
        width: 100%;
        height: auto;
    }

    .video__box-1 {
        margin: 0 auto;
        max-width: 1108px;

        .video-item {
            border-radius: 20px 20px 0px 0px;
            overflow: hidden;
        }
    }

    .content-box-1 {
        display: flex;
        column-gap: 25px;
        background: #fff;
        padding: 20px 38px;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 0px 0px 20px 20px;

        .img-col {
            display: flex;
            justify-content: center;
            align-items: center;
            column-gap: 12px;
            flex: 0 0 255px;

            .banner {
                max-width: 150px;

                @media screen and (max-width:768px) {
                    max-width: 126px;
                }

                @media screen and (max-width:479px) {
                    max-width: 82px;
                }
            }
        }

        .text-col {
            flex: 1;
            display: flex;
            align-items: center;
            column-gap: 15px;

            ul.styled {
                margin: 0;
                padding-left: 0;
                list-style-type: none;

                li {
                    position: relative;
                    padding-left: 30px;
                    margin-bottom: 12px;
                    font-weight: 400;
                    font-size: 18px;
                    line-height: 28px;

                    &::before {
                        content: "";
                        position: absolute;
                        left: 0;
                        top: 5px;
                        display: block;
                        width: 23px;
                        height: 16px;
                        background: url("../../img/domain/naturelawncare-com/icons/li-check.svg") center center/cover;
                    }
                }
            }
        }

        @media screen and (min-width: 1100px) {
            padding-left: 100px;

            .img-col {
                padding-right: 20px;
            }
        }

        @media screen and (max-width: 768px) {
            flex: 0 0 100%;
            flex-direction: column;

            .img-col {
                flex: 0 0 100%;
                justify-content: center;
                margin-bottom: 12px;
            }

            .text-col {
                flex-wrap: wrap;
            }
        }

        @media screen and (max-width: 480px) {
            padding: 20px 15px;

            .text-col {
                ul.styled {
                    li {
                        font-size: 16px;
                        line-height: 120%;

                        &::before {
                            top: 1px;
                        }
                    }
                }
            }
        }

        @media screen and (max-width: 360px) {
            .img-col {
                .banner {
                    max-width: 100px;
                }
            }
        }
    }

    .top-section-box {
        padding: 40px 0 30px 0;
        @media screen and (max-width:479px) {
            padding: 40px 0 0 0 ;
        }

        .h2,
        .decoration-text {
            text-align: center;
        }

        @media screen and (max-width: 480px) {
            .container {
                display: flex;
                flex-direction: column;

                .h2 {
                    order: 1;
                    margin-bottom: 15px;
                }

                .decoration-text-box {
                    order: 3;
                }

                .video__box-1 {
                    order: 2;
                }
            }
        }
    }

    .option-section-box {
        padding: 60px 0;

        .container {
            display: flex;
            column-gap: 19px;
            align-items: center;

            &:first-child {
                margin-bottom: 54px;
            }
        }

        .video-item {
            flex: 0 0 48%;

            @media screen and (max-width:768px) {
                width: 100%;
            }

            .videoBlock {
                border-radius: 20px;
                overflow: hidden;
            }
        }

        .info-item {
            position: relative;
            flex: 0 1 53%;
            padding: 20px;
            box-shadow: 0px 0px 14px rgba(0, 0, 0, 0.25);
            border-radius: 20px;

            @media screen and (max-width:480px) {
                padding: 0;
                border-radius: 0;
                box-shadow: none;
            }

            .text-wrap {
                display: flex;
                align-items: flex-start;
                gap: 10px;

                @media screen and (max-width: 480px) {
                    flex-direction: column;
                    align-items: center;
                    text-align: center;

                    &.reverce-mob {
                        flex-direction: column-reverse;
                    }
                }

                .banner {
                    max-width: 105px;

                    @media screen and (max-width: 480px) {
                        max-width: 140px;
                    }

                    &.sm {
                        max-width: 95px;

                        @media screen and (max-width: 480px) {
                            max-width: 133px;
                        }
                    }
                }

            }
        }

        .text-wrap {
            .upp-title {
                display: inline-block;
                margin-bottom: 18px;
                font-weight: 500;
                font-size: 22px;
                line-height: 150%;
                color: #00b67a;
                text-transform: uppercase;
            }

            .h5 {
                margin-bottom: 11px;
            }

            p {
                font-weight: 400;
                font-size: 18px;
                line-height: 125%;

                @media screen and (max-width:480px) {
                    font-size: 14px;
                    line-height: 17px;
                }
            }
        }

        @media screen and (max-width: 768px) {
            .container {
                flex-direction: column;

                &:first-child {
                    margin-bottom: 20px;
                }

                &.mob-reverse {
                    flex-direction: column-reverse;
                }
            }

            .info-item {
                margin-top: 20px;
            }
        }
    }

    .video__box-bg {
        padding: 60px 0;
        text-align: center;
        background: linear-gradient(0deg, #e3f1f8 0%, rgba(255, 255, 255, 0) 100%);

        .text-border {
            margin-bottom: 30px;
            padding: 10px 30px;
            background: #ffffff;
            border: 1px solid #97CDAA;
            border-radius: 30px;
            font-weight: 400;
            font-size: 24px;
            line-height: 120%;
        }

        .video-item {
            margin: 0 auto;
            max-width: 918px;

            .videoBlock {
                border-radius: 20px;
            }
        }

        @media screen and (max-width: 1000px) {
            .h2 {
                br {
                    display: none;
                }
            }
        }

        @media screen and (max-width: 768px) {
            padding: 40px 0;
        }

        @media screen and (max-width: 480px) {
            .text-border {
                margin-top: 20px;
                font-size: 20px;
            }
        }
    }

    .grid-content-box {
        padding: 60px 0;

        .h2 {
            margin: 0 auto 30px;
            max-width: 100%;
            text-align: center;
        }

        .items-box {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            column-gap: 22px;
        }

        .item {
            background: #1e1e1e;
            border-radius: 20px;
            overflow: hidden;
        }

        .video-item {
            position: relative;

            .circle {
                position: absolute;
                top: 10px;
                left: 10px;
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 34px;
                line-height: 34px;
                font-weight: 600;
                color: #fff;
                width: 80px;
                height: 80px;
                border-radius: 50%;
                background: #85C32F;

                span {
                    padding-top: 0;
                }
            }
        }

        .videoBlock {
            border-radius: 0;
        }

        .text-box {
            color: #fff;
            padding: 30px 24px;

            .title {
                margin-bottom: 17px;
                font-weight: 800;
                font-size: 22px;
                line-height: 115%;
            }

            .text {
                font-weight: 400;
                font-size: 16px;
                line-height: 150%;
            }
        }

        @media screen and (max-width: 1000px) {
            .items-box {
                grid-template-columns: 1fr;
                row-gap: 22px;
            }

            .item {
                display: flex;
            }

            .video-item {
                flex: 0 0 412px;

                .circle {
                    left: 10px;
                    right: auto;
                    width: 60px;
                    height: 60px;
                }

                .videoBlock {
                    height: 100%;
                    object-fit: cover;
                }
            }

            .text-box {
                flex: 1;
                display: flex;
                flex-direction: column;
                justify-content: center;
            }
        }

        @media screen and (max-width: 768px) {
            .video-item {
                flex: 0 0 322px;
            }
        }

        @media screen and (max-width: 767px) {
            .item {
                flex-direction: column;
            }

            .video-item {
                flex: 1;
            }
        }
    }

    //start here
    .why-us {
        background: url("../../img/domain/naturelawncare-com/trustpilot.jpg");
        padding: 60px 0;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;

        .h2 {
            text-align: center;

            @media screen and (max-width: 768px) {
                font-size: 36px;
                line-height: 47px;
            }

            @media screen and (max-width: 360px) {
                font-size: 30px;
            }
        }

        .why-us-wrap {
            display: grid;
            margin: 0 auto;
            grid-template-columns: 245px 1fr;
            column-gap: 65px;
            margin-top: 34px;
            max-width: 1055px;

            @media screen and (max-width: 1050px) {
                column-gap: 24px;
            }

            @media screen and (max-width: 890px) {
                grid-template-columns: 172px 1fr;

            }

            @media screen and (max-width: 768px) {
                margin-top: 0;
            }

            @media screen and (max-width: 767px) {
                padding-top: 24px;
                display: flex;
                flex-direction: column;
                align-items: center;
                gap: 15px;
            }

            .top-text {
                @media screen and (max-width: 480px) {
                    padding: 0;
                }
            }

            p {
                font-weight: 400;
                font-size: 18px;
                line-height: 125%;

                @media screen and (max-width: 360px) {
                    font-size: 16px;
                    line-height: 140%;
                }
            }

            .logo {
                @media screen and (max-width: 1000px) {
                    width: 100%;
                    max-width: 245px;
                    height: auto;
                }

                @media screen and (max-width: 890px) {
                    max-width: 172px;
                }
                @media screen and (max-width: 479px) {
                    max-width: 195px;
                }
            }

            .box-info-wrap {
                display: flex;
                column-gap: 15px;
                margin-top: 20px;

                @media screen and (max-width: 1000px) {
                    margin-bottom: 24px;
                    justify-content: center;
                }

                @media screen and (max-width: 890px) {
                    flex-direction: column;
                    row-gap: 11px;
                    margin-top: 20px;
                    margin-bottom: 40px;
                }

                @media screen and (max-width: 767px) {
                    flex-direction: row;
                }

                @media screen and (max-width: 479px) {
                    flex-direction: column;
                }

                .box {
                    background: #ffffff;
                    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
                    border-radius: 10px;
                    padding: 10px 20px;
                    display: flex;
                    column-gap: 10px;

                    @media screen and (max-width: 1000px) {
                        max-width: 340px;
                        width: 100%;
                        text-align: left;
                        padding: 10px 15px;
                    }

                    @media screen and (max-width: 479px) {
                        max-width: 100%;
                    }

                    img {
                        width: 36px;
                        height: 36px;
                    }

                    span {
                        font-family: "EuropeExt", sans-serif;
                        font-weight: 700;
                        font-size: 27px;
                        line-height: 25px;
                        display: flex;
                        align-items: center;
                        text-align: center;
                        color: #85C32F;
                    }

                    p {
                        font-weight: 500;
                        font-size: 18px;
                        line-height: 125%;
                        color: #383738;
                    }
                }
            }

            .btn {
                border-radius: 7px;
                text-align: center;
                text-decoration: none;
                padding: 17px;
                font-size: 20px;
                font-weight: 700;
                line-height: 23px;
                display: flex;
                align-items: center;
                justify-content: center;
                text-transform: uppercase;
                max-width: 367px;
                margin-top: 24px;
                margin-left: 75px;
                background: #85C32F;
                color: #ffffff;

                @media screen and (max-width: 1000px) {
                    margin-left: 30px;
                }

                @media screen and (max-width: 890px) {
                    margin: 0 auto;
                }
            }

            .btn-blue {
                background: rgba(55, 135, 255, 1);
                border-radius: 110px;
                text-align: center;
                text-decoration: none;
                padding: 15px;
                font-style: normal;
                font-weight: 700;
                font-size: 24px;
                justify-content: center;
                display: flex;
                align-items: center;
                text-transform: uppercase;
                color: #ffffff;
                line-height: 115%;
                max-width: 424px;
                margin-top: 34px;

                @media screen and (max-width: 1000px) {
                    margin: 0 auto;
                }
            }
        }
    }

    .zip-section {
        padding: 40px 0;
        background-position: center;
        background-size: cover;

        .btn-request {
            display: flex;
            justify-content: center;

            .btn-blue {
                background: rgba(55, 135, 255, 1);
                border-radius: 110px;
                width: 100%;
                max-width: 100%;
                text-align: center;
                padding: 11.5px 20px;
                height: 55px;
                font-weight: 700;
                font-size: 22px;
                line-height: 155%;
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
                -webkit-box-pack: center;
                -ms-flex-pack: center;
                justify-content: center;
                text-transform: uppercase;
                color: #fff;
                text-decoration: none;
                max-width: 290px;
                margin-top: 25px;
            }
        }

        .icons-down {
            .container {
                position: relative;
                width: 24px;
                height: 70px;
                display: flex;
                justify-content: center;
            }

            .chevron {
                position: absolute;
                width: 40px;
                height: 4px;
                opacity: 0;
                transform: scale3d(0.5, 0.5, 0.5);
                opacity: 1;
                animation: move 3s ease-out infinite;
            }

            .chevron:first-child {
                animation: move 3s ease-out 1s infinite;
            }

            .chevron:before,
            .chevron:after {
                content: ' ';
                position: absolute;
                top: 0;
                height: 100%;
                width: 51%;
                background: #10B278;
            }

            .chevron:before {
                left: 0;
                border-top-left-radius: 20px;
                border-bottom-left-radius: 20px;
                border-bottom-right-radius: 20px;
                transform: rotate(215deg);
            }

            .chevron:after {
                right: 6px;
                width: 50%;
                border-top-right-radius: 20px;
                border-bottom-left-radius: 20px;
                border-bottom-right-radius: 20px;
                -ms-transform: skew(0deg, -30deg);
                transform: rotate(145deg);
            }

            @keyframes move {
                25% {
                    opacity: 1;

                }

                33% {
                    opacity: 1;
                    transform: translateY(30px);
                }

                67% {
                    opacity: 1;
                    transform: translateY(40px);
                }

                100% {
                    opacity: 0;
                    transform: translateY(55px) scale3d(0.5, 0.5, 0.5);
                }
            }

            @keyframes pulse {
                to {
                    opacity: 1;
                }
            }
        }

        .h4 {
            color: #fff;
            text-align: center;
            font-size: 36px;
            font-style: normal;
            font-weight: 700;
            line-height: 115%;
            margin: 0 auto;

            span {
                color: #85C32F;
            }

            @media screen and (max-width: 768px) {
                margin: 0 auto;
                font-size: 22px;

                br {
                    display: none;
                }
            }
        }

        .h2 {
            font-weight: 700;
            font-size: 46px;
            line-height: 115%;
            text-align: center;
            color: #ffffff;

            @media screen and (max-width: 1000px) {
                br {
                    display: none;
                }
            }

            @media screen and (max-width: 768px) {
                margin: 0 auto;
                font-size: 36px;
            }

            @media screen and (max-width: 480px) {
                font-size: 26px;
            }

            @media screen and (max-width: 360px) {
                font-size: 24px;
            }
        }

        .subtitle {
            font-weight: 500;
            font-size: 30px;
            line-height: 115%;
            text-align: center;
            color: #ffffff;
            margin-top: 15px;

            @media screen and (max-width: 480px) {
                font-size: 20px;
                line-height: 115%;
            }
        }

        .tcpa-black {

            text-align: center;
            font-family: "Ubuntu", sans-serif;
            font-style: normal;
            font-weight: 400;
            line-height: 115%;
            margin-top: 20px;
            font-size: 10px;

            .tcpa-bot {
                margin-top: 10px;
                font-size: 10px;
                font-weight: 400;
                line-height: 11px;
                text-align: center;
                color: #A4A4A4;
                text-transform: uppercase;
            }

            .request {
                padding-top: 0;
                text-align: center;
                color: #B1B1B1;
                padding-bottom: 0;
                font-size: 10px;

                a {
                    color: #85C32F;
                    text-decoration: none;
                }
            }
        }

        #zip_tab,
        #address_tab,
        #email_tab {
            .form-section {
                padding: 70px 20px 20px 20px;

                @media screen and (max-width: 680px) {
                    padding: 37px 20px 20px 20px;
                }
            }
        }

        #address_tab,
        #email_tab {
            .title {
                margin-bottom: 20px;
            }
        }

        #problems_tab {
            .form {
                max-width: 100%;
                width: 100%;
            }
        }

        #windows_how_many {
            .form-section {
                padding: 44px 20px 20px 20px;

                @media screen and (max-width: 680px) {
                    padding: 37px 20px 20px 20px;
                }
            }
        }

        #names_tab {
            .form-section {
                padding: 50px 20px 20px 20px;

                @media screen and (max-width: 680px) {
                    padding: 37px 20px 20px 20px;
                }
            }
        }

        #phone_tab {
            .form-section {
                padding: 47px 20px 20px 20px;

                @media screen and (max-width: 680px) {
                    padding: 37px 20px 20px 20px;
                }
            }
        }

        #phone_tab {
            .form-section:has(.inputs-field.invalid) {
                padding: 45px 20px 35px 20px;

                .btn-prev {
                    bottom: 10px;
                }
            }
        }

        .form {
            margin: 10px auto 0 auto;
            max-width: 255px;

            @media screen and (max-width: 680px) {
                max-width: 100%;
            }

            .checkbox-btn+label:before,
            .radio-btn+label:before {
                display: none;
            }

            .inputs-item {
                margin-bottom: 0;
            }

            .inputs-field {
                margin-bottom: 10px;

                &.invalid {
                    margin-bottom: 25px;
                }

                &.valid {
                    .valid-text {
                        display: none;
                    }

                    input {
                        border: 1px solid #66aa4f !important;
                        background: #fff url(../../img/dist/icons/valid-input-icon.svg) no-repeat 95% center !important;
                    }
                }

                .valid-text {
                    display: none;
                    font-size: 12px;
                    text-align: left;
                    color: #66aa4f;
                    font-style: italic;
                    padding-top: 6px !important;
                    padding-bottom: 0;
                }
            }

            #zipInput,
            #emailInput,
            #firstNameInput,
            #lastNameInput,
            #phoneInput,
            #addressInput {
                background: #ffffff;
                border: 1px solid #D9D9D9;
                box-shadow: 0px 4px 14px 0px #44444430;
                border-radius: 8px;
                width: 100%;
                height: 44px;
                font-weight: 400;
                font-size: 16px;
                line-height: normal;
                color: #333;
                padding: 14px 20px;
                font-family: "Ubuntu", sans-serif;
                color: #333333;

                &::placeholder {
                    color: #333333;
                }

                &.no-valid {
                    border: 2px solid #E60023 !important;
                    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);

                    input {
                        border: none;

                        &::placeholder {
                            color: #E60023;
                        }
                    }

                    .error-text {
                        display: block;
                    }
                }
            }

            #addressInput.parsley-success,
            #addressInput.parsley-error {
                padding: 14px 35px 14px 20px;
            }

            #emailInput.parsley-success,
            #emailInput.parsley-error {
                padding: 14px 35px 14px 20px;
            }

            .btn-blue {
                background: #85C32F;
                border-radius: 8px;
                width: 100%;
                max-width: 100%;
                text-align: center;
                padding: 13px 20px;
                font-weight: 700;
                font-size: 16px;
                line-height: 18px;
                display: flex;
                justify-content: center;
                text-transform: uppercase;
                color: #ffffff;
                text-decoration: none;
            }
        }

        &.bg-gutter-first {
            background: url("../../img/domain/naturelawncare-com/zip1.jpg");
            background-position: center;
            background-size: cover;
        }

        &.bg-gutter-m {
            background: url("../../img/domain/naturelawncare-com/zip2.jpg");
            background-position: center;
            background-size: cover;

            .icons-down {
                margin-top: 20px;
            }
        }

        &.bg-gutter-last {
            background: url("../../img/domain/naturelawncare-com/zip3.jpg");
            background-position: center;
            background-size: cover;

            .icons-down {
                margin-top: 20px;
            }
        }
    }

    .offer-section {
        background: #e3f1f8;
        padding: 40px 0;

        .offer-section-wrap {
            display: flex;
            justify-content: flex-start;
            column-gap: 75px;
            align-items: center;

            img {
                max-width: 232px;

                @media screen and (max-width:1000px) {
                    max-width: 177px;

                }

                @media screen and (max-width:479px) {
                    max-width: 136px;

                }
            }

            @media screen and (max-width: 750px) {
                flex-direction: column;
                text-align: center;
                row-gap: 10px;
            }

            .h2 {
                font-weight: 700;
                font-size: 46px;
                line-height: 115%;
                color: #383738;

                @media screen and (max-width: 768px) {
                    margin: 0 auto;
                    font-size: 36px;
                }

                @media screen and (max-width: 480px) {
                    font-size: 26px;
                }

                @media screen and (max-width: 360px) {
                    font-size: 24px;
                }
            }

            p {
                font-weight: 400;
                font-size: 22px;
                line-height: 150%;
                color: #1a1a1a;
                margin-top: 20px;

                @media screen and (max-width: 768px) {
                    font-size: 20px;
                }
            }


        }
    }

    .comments-section {
        padding: 60px 0;

        .h2 {
            font-weight: 700;
            font-size: 46px;
            line-height: 120%;
            text-align: center;
            color: #1a1a1a;

            @media screen and (max-width: 768px) {
                margin: 0 auto;
                font-size: 36px;
            }

            @media screen and (max-width: 480px) {
                font-size: 26px;
            }

            @media screen and (max-width: 360px) {
                font-size: 24px;
            }
        }

        .icons-box {
            display: flex;
            column-gap: 16px;
            text-align: center;
            justify-content: center;
            margin-top: 30px;

            @media screen and (max-width: 480px) {
                flex-direction: column;
                row-gap: 16px;
                align-items: center;

                img:last-child {
                    width: 179.83px;
                    height: 59.5px;
                }

                img:first-child {
                    width: 165px;
                    height: 42px;
                }
            }
        }

        .comments-wrap {
            max-width: 925px;
            margin: 62px auto 0 auto;

            .comment {
                background: #ffffff;
                box-shadow: 0px 0px 14px rgba(0, 0, 0, 0.25);
                border-radius: 20px;
                padding: 30px 20px;
                margin-bottom: 25px;

                .people {
                    display: grid;
                    grid-template-columns: 40px 1fr;
                    column-gap: 20px;
                    margin-bottom: 14px;

                    .img-box {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        width: 40px;
                        height: 40px;
                        border-radius: 50%;
                        background: #e0f9f0;
                        overflow: hidden;

                        span {
                            color: #3b7c27;
                            text-align: center;
                            font-size: 18px;
                            font-weight: 500;
                        }
                    }

                    p {
                        font-weight: 400;
                        font-size: 16px;
                        line-height: 100%;
                        color: #1a1a1a;
                        margin-bottom: 5px;
                    }
                }

                h5 {
                    font-weight: 700;
                    font-size: 15.3px;
                    line-height: 23px;
                    color: #1a1a1a;
                    margin-bottom: 6px;
                    margin-top: 14px;
                }

                p {
                    color: #1A1A1A;
                    font-family: Ubuntu;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 125%;
                }
            }
        }
    }
}

.mobile__btn-wrap {
    padding: 10px 0;
    background: rgba(0, 0, 0, 0.6);
    justify-content: center;
    align-items: center;
    z-index: 10;

    .btn {
        display: block;
        text-align: center;
        text-decoration: none;
        padding: 14px 0;
        width: 100%;
        max-width: 235px;
        font-size: 16px;
        line-height: 1;
        color: #fff;
        font-weight: 700;
        background: #85C32F;
        border-radius: 7px;
    }

    @media screen and (max-width: 480px) {
        display: flex !important;
        position: fixed;
        left: 0;
        right: 0;
        bottom: 0;
    }
}

.questions {
    max-width: 900px;
    display: grid;
    row-gap: 20px;
    margin: 0 auto;
    position: relative;

    .wrap-step-bar {
        display: grid;
        grid-template-columns: 1fr 1fr;
        position: absolute;
        z-index: 1;
        width: 100%;
        top: 35px;

        @media screen and (max-width: 680px) {
            grid-template-columns: 1fr;
            top: calc(20px + 200px);
        }

        @media screen and (max-width: 479px) {
            top: calc(20px + 120px);
        }

        .bar-line {
            padding: 0 20px;
        }

        &.bottom-bar {
            @media screen and (max-width: 680px) {
                top: calc(20px + 200px)
            }

            @media screen and (max-width: 479px) {
                top: calc(20px + 190px)
            }
        }

        &.bottom-bar-two {
            @media screen and (max-width: 680px) {
                top: calc(20px + 200px)
            }

            @media screen and (max-width: 479px) {
                top: calc(20px + 190px)
            }

        }
    }

    .steps__bar {
        width: 100%;
        padding: 0;
        margin: 0;
        height: 7px;
        background: #BFBFBF47 !important;
        flex-grow: 1;
        box-shadow: none !important;
        border-radius: 36px;
        display: flex;
        align-items: center;
        position: relative;
    }

    .steps__bar_line {
        -webkit-box-shadow: none;
        box-shadow: none;
        border: none;
        height: 7px;
        border-radius: 36px;
        transition: all .5s;
        background: #85C32F;
    }

    .item {
        display: grid;
        grid-template-columns: 1fr 1fr;
        justify-content: center;
        align-items: center;
        margin-top: 10px;
        min-height: 380px;
        height: 100%;

        @media screen and (max-width: 680px) {
            grid-template-columns: 1fr;
            min-height: auto;
            height: auto;
        }

        .col {
            &.list-lead-info {
                background: white;
                padding: 20px 31px;
                width: 100%;
                height: 100%;
                border-top-left-radius: 20px;
                border-bottom-left-radius: 20px;

                @media screen and (max-width: 680px) {
                    border-top-left-radius: 20px;
                    border-top-right-radius: 20px;
                    border-bottom-left-radius: 0;
                    border-bottom-right-radius: 0;
                    padding: 5px 15px 0 15px;
                }

                &.first {
                    @media screen and (max-width: 680px) {
                        height: 200px;
                        overflow: scroll;
                    }

                    @media screen and (max-width: 479px) {
                        height: 190px;
                    }
                }

                &.second {
                    @media screen and (max-width: 680px) {
                        height: 200px;
                        overflow: scroll;
                    }

                    @media screen and (max-width: 479px) {
                        height: 190px;
                    }
                }

                .info {
                    .header-title {
                        color: #4F4F4F;
                        font-family: "Ubuntu", sans-serif;
                        font-size: 24px;
                        font-style: normal;
                        font-weight: 600;
                        line-height: 115%;
                        border-bottom: 1px solid #4F4F4F;
                    }

                    ul {
                        list-style-type: none;
                        padding: 0;
                        margin: 0;

                        li {
                            display: grid;
                            grid-template-columns: 250px 1fr;
                            padding: 30px 0 17px 0;
                            border-bottom: 1px dashed #828282;
                            column-gap: 15px;
                            align-items: center;

                            @media screen and (max-width: 680px) {
                                padding: 12px 0;
                            }

                            &.sm-inner {
                                padding: 12px 0 17px 0;

                                @media screen and (max-width: 680px) {
                                    padding: 12px 0;
                                }
                            }

                            @media screen and (max-width: 860px) {
                                grid-template-columns: 160px 1fr;
                            }

                            span {
                                color: #A4A4A4;
                                font-family: "Ubuntu", sans-serif;
                                font-size: 14px;
                                font-style: normal;
                                font-weight: 400;
                                line-height: 16px;
                                display: block;

                                @media screen and (max-width: 680px) {
                                    font-size: 14px;
                                }
                            }
                        }
                    }
                }
            }

            &.preview {
                overflow: hidden;
                background: white;
                width: 100%;
                height: 100%;
                border-top-left-radius: 20px;
                border-bottom-left-radius: 20px;
                position: relative;

                @media screen and (max-width: 680px) {
                    border-top-left-radius: 20px;
                    border-top-right-radius: 20px;
                    border-bottom-left-radius: 0;
                    border-bottom-right-radius: 0;
                    height: 200px;
                }

                @media screen and (max-width: 479px) {
                    height: 120px;
                }

                img {
                    height: 100%;
                    width: 100%;
                    border-top-left-radius: 20px;
                    border-bottom-left-radius: 20px;
                    object-fit: cover;

                    @media screen and (max-width: 680px) {
                        border-top-left-radius: 20px;
                        border-top-right-radius: 20px;
                        border-bottom-left-radius: 0;
                        border-bottom-right-radius: 0;
                    }
                }

                #map {
                    &:before {
                        content: "";
                        position: absolute;
                        width: 100%;
                        height: 100%;
                        top: 0;
                        right: 0;
                        bottom: 0;
                        left: 0;
                        margin: auto;
                        background: rgb(247 247 247 / 41%);
                        border-radius: 4px;
                        z-index: 8;
                    }

                    &:after {
                        content: "?";
                        position: absolute;
                        width: 115px;
                        height: 115px;
                        top: 0;
                        right: 0;
                        bottom: 0;
                        left: 0;
                        margin: auto;
                        display: -webkit-box;
                        display: -webkit-flex;
                        display: -ms-flexbox;
                        display: flex;
                        -webkit-box-align: center;
                        -webkit-align-items: center;
                        -ms-flex-align: center;
                        align-items: center;
                        -webkit-box-pack: center;
                        -webkit-justify-content: center;
                        -ms-flex-pack: center;
                        justify-content: center;
                        font-style: normal;
                        font-weight: 400;
                        font-size: 50px;
                        line-height: 32px;
                        color: rgba(135, 135, 135, 0.90);
                        border-radius: 50%;
                        border: 5px solid rgba(135, 135, 135, 0.90);
                        z-index: 9;

                        @media screen and (max-width: 680px) {
                            width: 70px;
                            height: 70px;
                            top: 50%;
                            transform: translateY(-50%);
                            margin: 0 auto 0px 20px;
                        }
                    }
                }

                #map2 {
                    &:after {
                        content: "";
                        position: absolute;
                        width: 190px;
                        height: 190px;
                        top: 0;
                        right: 0;
                        bottom: 0;
                        left: 0;
                        margin: auto;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        background: rgba(87, 168, 70, 0.20);
                        border-radius: 50%;
                        border: 3px solid rgba(15, 169, 88, 0.80);
                        z-index: 9;

                        @media screen and (max-width: 680px) {
                            width: 70px;
                            height: 70px;


                        }
                    }

                }

                #map3 {
                    &:after {
                        content: "";
                        position: absolute;
                        width: 50px;
                        height: 50px;
                        top: 0;
                        right: 0;
                        bottom: 0;
                        left: 0;
                        margin: auto;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        background: url("../../img/dist/gutter-protection-new/marker.png") center center/cover;
                        z-index: 9;
                    }
                }

                #map,
                #map2,
                #map3 {
                    display: block;
                    max-width: 100%;
                    max-height: 100%;
                    width: 100%;
                    height: 100%;
                    border-top-left-radius: 20px;
                    border-bottom-left-radius: 20px;
                    pointer-events: none;

                    @media screen and (max-width: 680px) {
                        border-top-left-radius: 20px;
                        border-top-right-radius: 20px;
                        border-bottom-left-radius: 0;
                        border-bottom-right-radius: 0;
                    }
                }
            }

            &.form-section {
                background: rgba(255, 255, 255, 0.96);
                padding: 70px 20px 20px 20px;
                border-top-right-radius: 20px;
                border-bottom-right-radius: 20px;
                backdrop-filter: blur(19px);
                height: 100%;

                @media screen and (max-width: 680px) {
                    border-top-right-radius: 0;
                    border-top-left-radius: 0;
                    border-bottom-right-radius: 20px;
                    border-bottom-left-radius: 20px;
                    min-height: 450px;
                    padding: 37px 20px 20px 20px;
                }

                .bottom-text {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    column-gap: 7px;
                    margin-top: 10px;

                    @media screen and (max-width: 680px) {
                        margin-top: 17px;
                    }

                    span {
                        color: #828282;
                        text-align: center;
                        font-size: 14px;
                        font-weight: 400;
                        display: block;
                    }
                }

                .title {
                    font-family: "Ubuntu", sans-serif;
                    font-size: 22px;
                    font-weight: 700;
                    line-height: 25.3px;
                    text-align: center;
                    display: block;
                    margin-bottom: 10px;

                    @media screen and (max-width: 680px) {
                        font-size: 22px;
                    }
                }

                .inputs.grid-3 {
                    display: grid;
                    grid-template-columns: repeat(2, 1fr);
                    gap: 10px;

                    @media screen and (max-width: 680px) {
                        gap: 5px;
                        display: flex;
                        flex-direction: column;
                    }
                }

                .checkbox-btn,
                .radio-btn {
                    position: absolute;
                    opacity: 0;
                }

                .steps__roofing_form_checkbox label {
                    height: 100% !important;
                    box-shadow: none;
                    margin-bottom: 0;
                    cursor: pointer;
                    padding: 11px 20px;
                    text-align: left;
                    background: #FFF;
                    font-family: "Ubuntu", sans-serif;
                    font-style: normal;
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 18px;
                    color: #333333;
                    border: 2px solid #D9D9D9;
                    border-radius: 8px;
                    display: flex;
                    align-items: center;
                }

                .steps__roofing_form_checkbox .checkbox-btn:checked+label {
                    background: #85C32F;
                    border: 2px solid #85C32F;
                    color: #FFF;
                    font-weight: 700;
                }
            }
        }

        .steps__buttons {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            .btn-prev {
                position: absolute;
                bottom: 20px;
            }
        }

        .steps__buttons {
            .btn-prev {
                margin: 20% 0 0 0;
                width: 100% !important;
                max-width: 125px;
                padding: 0;
                background: 0 0;
                border: none;
                display: block;
                margin: 15px auto 0 auto;
                text-align: center;
                font-size: 16px;
                font-weight: 500;
                line-height: 150%;
                color: #828282;
                text-transform: uppercase;
                display: flex;
                justify-content: center;
                align-items: center;
                column-gap: 6px;

                span {
                    display: block;
                }
            }
        }

        // .form .error-text {
        //     display: none;
        // }
    }
}

.banner-map {
    bottom: auto;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    max-width: 350px;
    top: 20px;
    position: absolute;
    color: #fff;
    font-family: "Ubuntu", sans-serif;
    font-size: 17px;
    font-style: normal;
    font-weight: 600;
    line-height: 130%;
    border-radius: 15px;
    padding: 7px 15px;
    z-index: 10;
    background: #85C32F;

    @media screen and (max-width:767px) {
        max-width: 70%;
        text-align: center;
    }

    @media screen and (max-width:680px) {
        max-width: 250px;
        right: 20px;
        left: auto;
        transform: translate(0, -50%);
        top: 50%;

    }

    @media screen and (max-width:479px) {
        font-size: 14px;
        line-height: 16px;
        max-width: 200px;
    }
}