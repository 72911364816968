@font-face {
  font-family: "EuropeExt";
  src: url("../../fonts/gutterprotect/europeext_bold.woff") format("woff2");
  font-weight: bold;
}
.naturelawncare-inner {
  font-family: "Ubuntu", sans-serif;
}

.gp__header-home {
  padding: 21px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #FFFFFF;
  border-bottom: 1px solid #E2E2E2;
}
.gp__header-home .logo {
  max-width: 290px;
}
@media screen and (max-width: 768px) {
  .gp__header-home .logo {
    max-width: 218px;
  }
}
@media screen and (max-width: 520px) {
  .gp__header-home .logo {
    max-width: 192px;
  }
}
@media screen and (max-width: 479px) {
  .gp__header-home .logo {
    max-width: 156px;
  }
}

.naturelawncare-footer {
  background: #333333;
}
.naturelawncare-footer a:hover {
  text-decoration: none !important;
}
.naturelawncare-footer .container {
  padding: 40px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
@media (max-width: 800px) {
  .naturelawncare-footer .container {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 25px 15px;
  }
}
.naturelawncare-footer .footer__inner_menu {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
}
@media (max-width: 800px) {
  .naturelawncare-footer .footer__inner_menu {
    width: 100%;
    padding-bottom: 15px;
  }
}
@media (max-width: 400px) {
  .naturelawncare-footer .footer__inner_menu {
    flex-direction: column;
  }
}
.naturelawncare-footer .footer__inner_menu li {
  margin-bottom: 0;
  padding: 0 20px;
}
@media (max-width: 800px) {
  .naturelawncare-footer .footer__inner_menu li {
    justify-content: center;
    display: flex;
  }
}
@media (max-width: 400px) {
  .naturelawncare-footer .footer__inner_menu li {
    padding: 20px;
  }
}
.naturelawncare-footer-rights {
  display: flex;
  align-items: center;
  gap: 13px;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #A4A4A4;
}
.naturelawncare-footer-rights img {
  width: 36px;
  height: 36px;
}
.naturelawncare-footer-logo {
  max-width: 225px;
}
@media (max-width: 800px) {
  .naturelawncare-footer-logo {
    padding-bottom: 15px;
  }
}
@media (max-width: 479px) {
  .naturelawncare-footer-logo {
    max-width: 160px;
  }
}
.naturelawncare-footer-logo img {
  width: 100%;
}

.has-phone-btn {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.has-phone-btn .logo-icon {
  max-width: 200px;
}
@media screen and (max-width: 480px) {
  .has-phone-btn .logo-icon {
    max-width: 150px;
  }
}
@media screen and (max-width: 360px) {
  .has-phone-btn .logo-icon {
    max-width: 139px;
  }
}
.has-phone-btn .phone-link {
  width: 100%;
  max-width: 255px;
  padding: 12px 0px;
  height: 49px;
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 10px;
  border-radius: 130px;
  background: #3787FF;
  transition: opacity 0.2s ease;
  text-decoration: none;
}
.has-phone-btn .phone-link:hover {
  opacity: 0.8;
}
.has-phone-btn .phone-link span {
  color: #FFF;
  text-align: center;
  font-size: 19px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
}
.has-phone-btn .phone-link svg {
  width: 18px;
  height: 31px;
}
@media screen and (max-width: 1000px) {
  .has-phone-btn .phone-link {
    max-width: 210px;
  }
}
@media screen and (max-width: 768px) {
  .has-phone-btn .phone-link {
    max-width: 195px;
    column-gap: 8px;
  }
  .has-phone-btn .phone-link svg {
    width: 12.694px;
    height: 21.861px;
  }
}
@media screen and (max-width: 480px) {
  .has-phone-btn .phone-link {
    max-width: 155px;
    height: 34px;
  }
  .has-phone-btn .phone-link span {
    font-size: 14px;
  }
  .has-phone-btn .phone-link svg {
    width: 8.89px;
    height: 15.31px;
  }
}
@media screen and (max-width: 360px) {
  .has-phone-btn .phone-link {
    max-width: 120px;
    height: 30px;
  }
  .has-phone-btn .phone-link span {
    font-size: 12px;
  }
}

.gp__main-home {
  font-family: "Ubuntu", sans-serif;
  color: #1a1a1a;
}
.gp__main-home .container {
  max-width: 1282px;
  padding: 0 20px;
}
.gp__main-home p {
  margin: 0;
}
.gp__main-home .h2 {
  margin: 0;
  font-size: 46px;
  font-weight: 700;
  line-height: 55.2px;
  color: #464646;
}
.gp__main-home .h2 span {
  color: #85C32F;
}
@media screen and (max-width: 1000px) {
  .gp__main-home .h2 {
    font-size: 42px;
    line-height: 50px;
  }
}
@media screen and (max-width: 768px) {
  .gp__main-home .h2 {
    font-size: 32px;
    line-height: 38px;
  }
}
@media screen and (max-width: 520px) {
  .gp__main-home .h2 {
    font-size: 26px;
    line-height: 31px;
  }
}
@media screen and (max-width: 479px) {
  .gp__main-home .h2 {
    font-size: 24px;
    line-height: 28px;
  }
}
.gp__main-home .h5 {
  margin: 0;
  font-weight: 700;
  font-size: 27px;
  line-height: 125%;
}
@media screen and (max-width: 480px) {
  .gp__main-home .h5 {
    font-size: 24px;
    line-height: 30px;
  }
}
.gp__main-home .decoration-text-box {
  text-align: center;
}
.gp__main-home .decoration-text {
  display: inline-block;
  margin: 10px 0 40px 0;
  padding: 10px 30px;
  font-weight: 500;
  font-size: 24px;
  line-height: 24px;
  background: #f2f2f2;
  border: 1px solid #CBDAFF;
  border-radius: 30px;
}
.gp__main-home .decoration-text span {
  color: #30a31a;
  text-decoration: underline;
}
@media screen and (max-width: 768px) {
  .gp__main-home .decoration-text {
    margin-top: 18px;
    font-size: 18px;
  }
}
@media screen and (max-width: 479px) {
  .gp__main-home .decoration-text {
    margin-top: 15px;
    font-size: 15px;
  }
}
.gp__main-home .videoBlock {
  display: block;
  width: 100%;
  height: auto;
}
.gp__main-home .video__box-1 {
  margin: 0 auto;
  max-width: 1108px;
}
.gp__main-home .video__box-1 .video-item {
  border-radius: 20px 20px 0px 0px;
  overflow: hidden;
}
.gp__main-home .content-box-1 {
  display: flex;
  column-gap: 25px;
  background: #fff;
  padding: 20px 38px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 0px 0px 20px 20px;
}
.gp__main-home .content-box-1 .img-col {
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 12px;
  flex: 0 0 255px;
}
.gp__main-home .content-box-1 .img-col .banner {
  max-width: 150px;
}
@media screen and (max-width: 768px) {
  .gp__main-home .content-box-1 .img-col .banner {
    max-width: 126px;
  }
}
@media screen and (max-width: 479px) {
  .gp__main-home .content-box-1 .img-col .banner {
    max-width: 82px;
  }
}
.gp__main-home .content-box-1 .text-col {
  flex: 1;
  display: flex;
  align-items: center;
  column-gap: 15px;
}
.gp__main-home .content-box-1 .text-col ul.styled {
  margin: 0;
  padding-left: 0;
  list-style-type: none;
}
.gp__main-home .content-box-1 .text-col ul.styled li {
  position: relative;
  padding-left: 30px;
  margin-bottom: 12px;
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
}
.gp__main-home .content-box-1 .text-col ul.styled li::before {
  content: "";
  position: absolute;
  left: 0;
  top: 5px;
  display: block;
  width: 23px;
  height: 16px;
  background: url("../../img/domain/naturelawncare-com/icons/li-check.svg") center center/cover;
}
@media screen and (min-width: 1100px) {
  .gp__main-home .content-box-1 {
    padding-left: 100px;
  }
  .gp__main-home .content-box-1 .img-col {
    padding-right: 20px;
  }
}
@media screen and (max-width: 768px) {
  .gp__main-home .content-box-1 {
    flex: 0 0 100%;
    flex-direction: column;
  }
  .gp__main-home .content-box-1 .img-col {
    flex: 0 0 100%;
    justify-content: center;
    margin-bottom: 12px;
  }
  .gp__main-home .content-box-1 .text-col {
    flex-wrap: wrap;
  }
}
@media screen and (max-width: 480px) {
  .gp__main-home .content-box-1 {
    padding: 20px 15px;
  }
  .gp__main-home .content-box-1 .text-col ul.styled li {
    font-size: 16px;
    line-height: 120%;
  }
  .gp__main-home .content-box-1 .text-col ul.styled li::before {
    top: 1px;
  }
}
@media screen and (max-width: 360px) {
  .gp__main-home .content-box-1 .img-col .banner {
    max-width: 100px;
  }
}
.gp__main-home .top-section-box {
  padding: 40px 0 30px 0;
}
@media screen and (max-width: 479px) {
  .gp__main-home .top-section-box {
    padding: 40px 0 0 0;
  }
}
.gp__main-home .top-section-box .h2,
.gp__main-home .top-section-box .decoration-text {
  text-align: center;
}
@media screen and (max-width: 480px) {
  .gp__main-home .top-section-box .container {
    display: flex;
    flex-direction: column;
  }
  .gp__main-home .top-section-box .container .h2 {
    order: 1;
    margin-bottom: 15px;
  }
  .gp__main-home .top-section-box .container .decoration-text-box {
    order: 3;
  }
  .gp__main-home .top-section-box .container .video__box-1 {
    order: 2;
  }
}
.gp__main-home .option-section-box {
  padding: 60px 0;
}
.gp__main-home .option-section-box .container {
  display: flex;
  column-gap: 19px;
  align-items: center;
}
.gp__main-home .option-section-box .container:first-child {
  margin-bottom: 54px;
}
.gp__main-home .option-section-box .video-item {
  flex: 0 0 48%;
}
@media screen and (max-width: 768px) {
  .gp__main-home .option-section-box .video-item {
    width: 100%;
  }
}
.gp__main-home .option-section-box .video-item .videoBlock {
  border-radius: 20px;
  overflow: hidden;
}
.gp__main-home .option-section-box .info-item {
  position: relative;
  flex: 0 1 53%;
  padding: 20px;
  box-shadow: 0px 0px 14px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
}
@media screen and (max-width: 480px) {
  .gp__main-home .option-section-box .info-item {
    padding: 0;
    border-radius: 0;
    box-shadow: none;
  }
}
.gp__main-home .option-section-box .info-item .text-wrap {
  display: flex;
  align-items: flex-start;
  gap: 10px;
}
@media screen and (max-width: 480px) {
  .gp__main-home .option-section-box .info-item .text-wrap {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
  .gp__main-home .option-section-box .info-item .text-wrap.reverce-mob {
    flex-direction: column-reverse;
  }
}
.gp__main-home .option-section-box .info-item .text-wrap .banner {
  max-width: 105px;
}
@media screen and (max-width: 480px) {
  .gp__main-home .option-section-box .info-item .text-wrap .banner {
    max-width: 140px;
  }
}
.gp__main-home .option-section-box .info-item .text-wrap .banner.sm {
  max-width: 95px;
}
@media screen and (max-width: 480px) {
  .gp__main-home .option-section-box .info-item .text-wrap .banner.sm {
    max-width: 133px;
  }
}
.gp__main-home .option-section-box .text-wrap .upp-title {
  display: inline-block;
  margin-bottom: 18px;
  font-weight: 500;
  font-size: 22px;
  line-height: 150%;
  color: #00b67a;
  text-transform: uppercase;
}
.gp__main-home .option-section-box .text-wrap .h5 {
  margin-bottom: 11px;
}
.gp__main-home .option-section-box .text-wrap p {
  font-weight: 400;
  font-size: 18px;
  line-height: 125%;
}
@media screen and (max-width: 480px) {
  .gp__main-home .option-section-box .text-wrap p {
    font-size: 14px;
    line-height: 17px;
  }
}
@media screen and (max-width: 768px) {
  .gp__main-home .option-section-box .container {
    flex-direction: column;
  }
  .gp__main-home .option-section-box .container:first-child {
    margin-bottom: 20px;
  }
  .gp__main-home .option-section-box .container.mob-reverse {
    flex-direction: column-reverse;
  }
  .gp__main-home .option-section-box .info-item {
    margin-top: 20px;
  }
}
.gp__main-home .video__box-bg {
  padding: 60px 0;
  text-align: center;
  background: linear-gradient(0deg, #e3f1f8 0%, rgba(255, 255, 255, 0) 100%);
}
.gp__main-home .video__box-bg .text-border {
  margin-bottom: 30px;
  padding: 10px 30px;
  background: #ffffff;
  border: 1px solid #97CDAA;
  border-radius: 30px;
  font-weight: 400;
  font-size: 24px;
  line-height: 120%;
}
.gp__main-home .video__box-bg .video-item {
  margin: 0 auto;
  max-width: 918px;
}
.gp__main-home .video__box-bg .video-item .videoBlock {
  border-radius: 20px;
}
@media screen and (max-width: 1000px) {
  .gp__main-home .video__box-bg .h2 br {
    display: none;
  }
}
@media screen and (max-width: 768px) {
  .gp__main-home .video__box-bg {
    padding: 40px 0;
  }
}
@media screen and (max-width: 480px) {
  .gp__main-home .video__box-bg .text-border {
    margin-top: 20px;
    font-size: 20px;
  }
}
.gp__main-home .grid-content-box {
  padding: 60px 0;
}
.gp__main-home .grid-content-box .h2 {
  margin: 0 auto 30px;
  max-width: 100%;
  text-align: center;
}
.gp__main-home .grid-content-box .items-box {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 22px;
}
.gp__main-home .grid-content-box .item {
  background: #1e1e1e;
  border-radius: 20px;
  overflow: hidden;
}
.gp__main-home .grid-content-box .video-item {
  position: relative;
}
.gp__main-home .grid-content-box .video-item .circle {
  position: absolute;
  top: 10px;
  left: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 34px;
  line-height: 34px;
  font-weight: 600;
  color: #fff;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  background: #85C32F;
}
.gp__main-home .grid-content-box .video-item .circle span {
  padding-top: 0;
}
.gp__main-home .grid-content-box .videoBlock {
  border-radius: 0;
}
.gp__main-home .grid-content-box .text-box {
  color: #fff;
  padding: 30px 24px;
}
.gp__main-home .grid-content-box .text-box .title {
  margin-bottom: 17px;
  font-weight: 800;
  font-size: 22px;
  line-height: 115%;
}
.gp__main-home .grid-content-box .text-box .text {
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
}
@media screen and (max-width: 1000px) {
  .gp__main-home .grid-content-box .items-box {
    grid-template-columns: 1fr;
    row-gap: 22px;
  }
  .gp__main-home .grid-content-box .item {
    display: flex;
  }
  .gp__main-home .grid-content-box .video-item {
    flex: 0 0 412px;
  }
  .gp__main-home .grid-content-box .video-item .circle {
    left: 10px;
    right: auto;
    width: 60px;
    height: 60px;
  }
  .gp__main-home .grid-content-box .video-item .videoBlock {
    height: 100%;
    object-fit: cover;
  }
  .gp__main-home .grid-content-box .text-box {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}
@media screen and (max-width: 768px) {
  .gp__main-home .grid-content-box .video-item {
    flex: 0 0 322px;
  }
}
@media screen and (max-width: 767px) {
  .gp__main-home .grid-content-box .item {
    flex-direction: column;
  }
  .gp__main-home .grid-content-box .video-item {
    flex: 1;
  }
}
.gp__main-home .why-us {
  background: url("../../img/domain/naturelawncare-com/trustpilot.jpg");
  padding: 60px 0;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
.gp__main-home .why-us .h2 {
  text-align: center;
}
@media screen and (max-width: 768px) {
  .gp__main-home .why-us .h2 {
    font-size: 36px;
    line-height: 47px;
  }
}
@media screen and (max-width: 360px) {
  .gp__main-home .why-us .h2 {
    font-size: 30px;
  }
}
.gp__main-home .why-us .why-us-wrap {
  display: grid;
  margin: 0 auto;
  grid-template-columns: 245px 1fr;
  column-gap: 65px;
  margin-top: 34px;
  max-width: 1055px;
}
@media screen and (max-width: 1050px) {
  .gp__main-home .why-us .why-us-wrap {
    column-gap: 24px;
  }
}
@media screen and (max-width: 890px) {
  .gp__main-home .why-us .why-us-wrap {
    grid-template-columns: 172px 1fr;
  }
}
@media screen and (max-width: 768px) {
  .gp__main-home .why-us .why-us-wrap {
    margin-top: 0;
  }
}
@media screen and (max-width: 767px) {
  .gp__main-home .why-us .why-us-wrap {
    padding-top: 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 15px;
  }
}
@media screen and (max-width: 480px) {
  .gp__main-home .why-us .why-us-wrap .top-text {
    padding: 0;
  }
}
.gp__main-home .why-us .why-us-wrap p {
  font-weight: 400;
  font-size: 18px;
  line-height: 125%;
}
@media screen and (max-width: 360px) {
  .gp__main-home .why-us .why-us-wrap p {
    font-size: 16px;
    line-height: 140%;
  }
}
@media screen and (max-width: 1000px) {
  .gp__main-home .why-us .why-us-wrap .logo {
    width: 100%;
    max-width: 245px;
    height: auto;
  }
}
@media screen and (max-width: 890px) {
  .gp__main-home .why-us .why-us-wrap .logo {
    max-width: 172px;
  }
}
@media screen and (max-width: 479px) {
  .gp__main-home .why-us .why-us-wrap .logo {
    max-width: 195px;
  }
}
.gp__main-home .why-us .why-us-wrap .box-info-wrap {
  display: flex;
  column-gap: 15px;
  margin-top: 20px;
}
@media screen and (max-width: 1000px) {
  .gp__main-home .why-us .why-us-wrap .box-info-wrap {
    margin-bottom: 24px;
    justify-content: center;
  }
}
@media screen and (max-width: 890px) {
  .gp__main-home .why-us .why-us-wrap .box-info-wrap {
    flex-direction: column;
    row-gap: 11px;
    margin-top: 20px;
    margin-bottom: 40px;
  }
}
@media screen and (max-width: 767px) {
  .gp__main-home .why-us .why-us-wrap .box-info-wrap {
    flex-direction: row;
  }
}
@media screen and (max-width: 479px) {
  .gp__main-home .why-us .why-us-wrap .box-info-wrap {
    flex-direction: column;
  }
}
.gp__main-home .why-us .why-us-wrap .box-info-wrap .box {
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  padding: 10px 20px;
  display: flex;
  column-gap: 10px;
}
@media screen and (max-width: 1000px) {
  .gp__main-home .why-us .why-us-wrap .box-info-wrap .box {
    max-width: 340px;
    width: 100%;
    text-align: left;
    padding: 10px 15px;
  }
}
@media screen and (max-width: 479px) {
  .gp__main-home .why-us .why-us-wrap .box-info-wrap .box {
    max-width: 100%;
  }
}
.gp__main-home .why-us .why-us-wrap .box-info-wrap .box img {
  width: 36px;
  height: 36px;
}
.gp__main-home .why-us .why-us-wrap .box-info-wrap .box span {
  font-family: "EuropeExt", sans-serif;
  font-weight: 700;
  font-size: 27px;
  line-height: 25px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #85C32F;
}
.gp__main-home .why-us .why-us-wrap .box-info-wrap .box p {
  font-weight: 500;
  font-size: 18px;
  line-height: 125%;
  color: #383738;
}
.gp__main-home .why-us .why-us-wrap .btn {
  border-radius: 7px;
  text-align: center;
  text-decoration: none;
  padding: 17px;
  font-size: 20px;
  font-weight: 700;
  line-height: 23px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  max-width: 367px;
  margin-top: 24px;
  margin-left: 75px;
  background: #85C32F;
  color: #ffffff;
}
@media screen and (max-width: 1000px) {
  .gp__main-home .why-us .why-us-wrap .btn {
    margin-left: 30px;
  }
}
@media screen and (max-width: 890px) {
  .gp__main-home .why-us .why-us-wrap .btn {
    margin: 0 auto;
  }
}
.gp__main-home .why-us .why-us-wrap .btn-blue {
  background: rgb(55, 135, 255);
  border-radius: 110px;
  text-align: center;
  text-decoration: none;
  padding: 15px;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  justify-content: center;
  display: flex;
  align-items: center;
  text-transform: uppercase;
  color: #ffffff;
  line-height: 115%;
  max-width: 424px;
  margin-top: 34px;
}
@media screen and (max-width: 1000px) {
  .gp__main-home .why-us .why-us-wrap .btn-blue {
    margin: 0 auto;
  }
}
.gp__main-home .zip-section {
  padding: 40px 0;
  background-position: center;
  background-size: cover;
}
.gp__main-home .zip-section .btn-request {
  display: flex;
  justify-content: center;
}
.gp__main-home .zip-section .btn-request .btn-blue {
  background: rgb(55, 135, 255);
  border-radius: 110px;
  width: 100%;
  max-width: 100%;
  text-align: center;
  padding: 11.5px 20px;
  height: 55px;
  font-weight: 700;
  font-size: 22px;
  line-height: 155%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  text-transform: uppercase;
  color: #fff;
  text-decoration: none;
  max-width: 290px;
  margin-top: 25px;
}
.gp__main-home .zip-section .icons-down .container {
  position: relative;
  width: 24px;
  height: 70px;
  display: flex;
  justify-content: center;
}
.gp__main-home .zip-section .icons-down .chevron {
  position: absolute;
  width: 40px;
  height: 4px;
  opacity: 0;
  transform: scale3d(0.5, 0.5, 0.5);
  opacity: 1;
  animation: move 3s ease-out infinite;
}
.gp__main-home .zip-section .icons-down .chevron:first-child {
  animation: move 3s ease-out 1s infinite;
}
.gp__main-home .zip-section .icons-down .chevron:before,
.gp__main-home .zip-section .icons-down .chevron:after {
  content: " ";
  position: absolute;
  top: 0;
  height: 100%;
  width: 51%;
  background: #10B278;
}
.gp__main-home .zip-section .icons-down .chevron:before {
  left: 0;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  transform: rotate(215deg);
}
.gp__main-home .zip-section .icons-down .chevron:after {
  right: 6px;
  width: 50%;
  border-top-right-radius: 20px;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  -ms-transform: skew(0deg, -30deg);
  transform: rotate(145deg);
}
@keyframes move {
  25% {
    opacity: 1;
  }
  33% {
    opacity: 1;
    transform: translateY(30px);
  }
  67% {
    opacity: 1;
    transform: translateY(40px);
  }
  100% {
    opacity: 0;
    transform: translateY(55px) scale3d(0.5, 0.5, 0.5);
  }
}
@keyframes pulse {
  to {
    opacity: 1;
  }
}
.gp__main-home .zip-section .h4 {
  color: #fff;
  text-align: center;
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: 115%;
  margin: 0 auto;
}
.gp__main-home .zip-section .h4 span {
  color: #85C32F;
}
@media screen and (max-width: 768px) {
  .gp__main-home .zip-section .h4 {
    margin: 0 auto;
    font-size: 22px;
  }
  .gp__main-home .zip-section .h4 br {
    display: none;
  }
}
.gp__main-home .zip-section .h2 {
  font-weight: 700;
  font-size: 46px;
  line-height: 115%;
  text-align: center;
  color: #ffffff;
}
@media screen and (max-width: 1000px) {
  .gp__main-home .zip-section .h2 br {
    display: none;
  }
}
@media screen and (max-width: 768px) {
  .gp__main-home .zip-section .h2 {
    margin: 0 auto;
    font-size: 36px;
  }
}
@media screen and (max-width: 480px) {
  .gp__main-home .zip-section .h2 {
    font-size: 26px;
  }
}
@media screen and (max-width: 360px) {
  .gp__main-home .zip-section .h2 {
    font-size: 24px;
  }
}
.gp__main-home .zip-section .subtitle {
  font-weight: 500;
  font-size: 30px;
  line-height: 115%;
  text-align: center;
  color: #ffffff;
  margin-top: 15px;
}
@media screen and (max-width: 480px) {
  .gp__main-home .zip-section .subtitle {
    font-size: 20px;
    line-height: 115%;
  }
}
.gp__main-home .zip-section .tcpa-black {
  text-align: center;
  font-family: "Ubuntu", sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 115%;
  margin-top: 20px;
  font-size: 10px;
}
.gp__main-home .zip-section .tcpa-black .tcpa-bot {
  margin-top: 10px;
  font-size: 10px;
  font-weight: 400;
  line-height: 11px;
  text-align: center;
  color: #A4A4A4;
  text-transform: uppercase;
}
.gp__main-home .zip-section .tcpa-black .request {
  padding-top: 0;
  text-align: center;
  color: #B1B1B1;
  padding-bottom: 0;
  font-size: 10px;
}
.gp__main-home .zip-section .tcpa-black .request a {
  color: #85C32F;
  text-decoration: none;
}
.gp__main-home .zip-section #zip_tab .form-section,
.gp__main-home .zip-section #address_tab .form-section,
.gp__main-home .zip-section #email_tab .form-section {
  padding: 70px 20px 20px 20px;
}
@media screen and (max-width: 680px) {
  .gp__main-home .zip-section #zip_tab .form-section,
  .gp__main-home .zip-section #address_tab .form-section,
  .gp__main-home .zip-section #email_tab .form-section {
    padding: 37px 20px 20px 20px;
  }
}
.gp__main-home .zip-section #address_tab .title,
.gp__main-home .zip-section #email_tab .title {
  margin-bottom: 20px;
}
.gp__main-home .zip-section #problems_tab .form {
  max-width: 100%;
  width: 100%;
}
.gp__main-home .zip-section #windows_how_many .form-section {
  padding: 44px 20px 20px 20px;
}
@media screen and (max-width: 680px) {
  .gp__main-home .zip-section #windows_how_many .form-section {
    padding: 37px 20px 20px 20px;
  }
}
.gp__main-home .zip-section #names_tab .form-section {
  padding: 50px 20px 20px 20px;
}
@media screen and (max-width: 680px) {
  .gp__main-home .zip-section #names_tab .form-section {
    padding: 37px 20px 20px 20px;
  }
}
.gp__main-home .zip-section #phone_tab .form-section {
  padding: 47px 20px 20px 20px;
}
@media screen and (max-width: 680px) {
  .gp__main-home .zip-section #phone_tab .form-section {
    padding: 37px 20px 20px 20px;
  }
}
.gp__main-home .zip-section #phone_tab .form-section:has(.inputs-field.invalid) {
  padding: 45px 20px 35px 20px;
}
.gp__main-home .zip-section #phone_tab .form-section:has(.inputs-field.invalid) .btn-prev {
  bottom: 10px;
}
.gp__main-home .zip-section .form {
  margin: 10px auto 0 auto;
  max-width: 255px;
}
@media screen and (max-width: 680px) {
  .gp__main-home .zip-section .form {
    max-width: 100%;
  }
}
.gp__main-home .zip-section .form .checkbox-btn + label:before,
.gp__main-home .zip-section .form .radio-btn + label:before {
  display: none;
}
.gp__main-home .zip-section .form .inputs-item {
  margin-bottom: 0;
}
.gp__main-home .zip-section .form .inputs-field {
  margin-bottom: 10px;
}
.gp__main-home .zip-section .form .inputs-field.invalid {
  margin-bottom: 25px;
}
.gp__main-home .zip-section .form .inputs-field.valid .valid-text {
  display: none;
}
.gp__main-home .zip-section .form .inputs-field.valid input {
  border: 1px solid #66aa4f !important;
  background: #fff url(../../img/dist/icons/valid-input-icon.svg) no-repeat 95% center !important;
}
.gp__main-home .zip-section .form .inputs-field .valid-text {
  display: none;
  font-size: 12px;
  text-align: left;
  color: #66aa4f;
  font-style: italic;
  padding-top: 6px !important;
  padding-bottom: 0;
}
.gp__main-home .zip-section .form #zipInput,
.gp__main-home .zip-section .form #emailInput,
.gp__main-home .zip-section .form #firstNameInput,
.gp__main-home .zip-section .form #lastNameInput,
.gp__main-home .zip-section .form #phoneInput,
.gp__main-home .zip-section .form #addressInput {
  background: #ffffff;
  border: 1px solid #D9D9D9;
  box-shadow: 0px 4px 14px 0px rgba(68, 68, 68, 0.1882352941);
  border-radius: 8px;
  width: 100%;
  height: 44px;
  font-weight: 400;
  font-size: 16px;
  line-height: normal;
  color: #333;
  padding: 14px 20px;
  font-family: "Ubuntu", sans-serif;
  color: #333333;
}
.gp__main-home .zip-section .form #zipInput::placeholder,
.gp__main-home .zip-section .form #emailInput::placeholder,
.gp__main-home .zip-section .form #firstNameInput::placeholder,
.gp__main-home .zip-section .form #lastNameInput::placeholder,
.gp__main-home .zip-section .form #phoneInput::placeholder,
.gp__main-home .zip-section .form #addressInput::placeholder {
  color: #333333;
}
.gp__main-home .zip-section .form #zipInput.no-valid,
.gp__main-home .zip-section .form #emailInput.no-valid,
.gp__main-home .zip-section .form #firstNameInput.no-valid,
.gp__main-home .zip-section .form #lastNameInput.no-valid,
.gp__main-home .zip-section .form #phoneInput.no-valid,
.gp__main-home .zip-section .form #addressInput.no-valid {
  border: 2px solid #E60023 !important;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
}
.gp__main-home .zip-section .form #zipInput.no-valid input,
.gp__main-home .zip-section .form #emailInput.no-valid input,
.gp__main-home .zip-section .form #firstNameInput.no-valid input,
.gp__main-home .zip-section .form #lastNameInput.no-valid input,
.gp__main-home .zip-section .form #phoneInput.no-valid input,
.gp__main-home .zip-section .form #addressInput.no-valid input {
  border: none;
}
.gp__main-home .zip-section .form #zipInput.no-valid input::placeholder,
.gp__main-home .zip-section .form #emailInput.no-valid input::placeholder,
.gp__main-home .zip-section .form #firstNameInput.no-valid input::placeholder,
.gp__main-home .zip-section .form #lastNameInput.no-valid input::placeholder,
.gp__main-home .zip-section .form #phoneInput.no-valid input::placeholder,
.gp__main-home .zip-section .form #addressInput.no-valid input::placeholder {
  color: #E60023;
}
.gp__main-home .zip-section .form #zipInput.no-valid .error-text,
.gp__main-home .zip-section .form #emailInput.no-valid .error-text,
.gp__main-home .zip-section .form #firstNameInput.no-valid .error-text,
.gp__main-home .zip-section .form #lastNameInput.no-valid .error-text,
.gp__main-home .zip-section .form #phoneInput.no-valid .error-text,
.gp__main-home .zip-section .form #addressInput.no-valid .error-text {
  display: block;
}
.gp__main-home .zip-section .form #addressInput.parsley-success,
.gp__main-home .zip-section .form #addressInput.parsley-error {
  padding: 14px 35px 14px 20px;
}
.gp__main-home .zip-section .form #emailInput.parsley-success,
.gp__main-home .zip-section .form #emailInput.parsley-error {
  padding: 14px 35px 14px 20px;
}
.gp__main-home .zip-section .form .btn-blue {
  background: #85C32F;
  border-radius: 8px;
  width: 100%;
  max-width: 100%;
  text-align: center;
  padding: 13px 20px;
  font-weight: 700;
  font-size: 16px;
  line-height: 18px;
  display: flex;
  justify-content: center;
  text-transform: uppercase;
  color: #ffffff;
  text-decoration: none;
}
.gp__main-home .zip-section.bg-gutter-first {
  background: url("../../img/domain/naturelawncare-com/zip1.jpg");
  background-position: center;
  background-size: cover;
}
.gp__main-home .zip-section.bg-gutter-m {
  background: url("../../img/domain/naturelawncare-com/zip2.jpg");
  background-position: center;
  background-size: cover;
}
.gp__main-home .zip-section.bg-gutter-m .icons-down {
  margin-top: 20px;
}
.gp__main-home .zip-section.bg-gutter-last {
  background: url("../../img/domain/naturelawncare-com/zip3.jpg");
  background-position: center;
  background-size: cover;
}
.gp__main-home .zip-section.bg-gutter-last .icons-down {
  margin-top: 20px;
}
.gp__main-home .offer-section {
  background: #e3f1f8;
  padding: 40px 0;
}
.gp__main-home .offer-section .offer-section-wrap {
  display: flex;
  justify-content: flex-start;
  column-gap: 75px;
  align-items: center;
}
.gp__main-home .offer-section .offer-section-wrap img {
  max-width: 232px;
}
@media screen and (max-width: 1000px) {
  .gp__main-home .offer-section .offer-section-wrap img {
    max-width: 177px;
  }
}
@media screen and (max-width: 479px) {
  .gp__main-home .offer-section .offer-section-wrap img {
    max-width: 136px;
  }
}
@media screen and (max-width: 750px) {
  .gp__main-home .offer-section .offer-section-wrap {
    flex-direction: column;
    text-align: center;
    row-gap: 10px;
  }
}
.gp__main-home .offer-section .offer-section-wrap .h2 {
  font-weight: 700;
  font-size: 46px;
  line-height: 115%;
  color: #383738;
}
@media screen and (max-width: 768px) {
  .gp__main-home .offer-section .offer-section-wrap .h2 {
    margin: 0 auto;
    font-size: 36px;
  }
}
@media screen and (max-width: 480px) {
  .gp__main-home .offer-section .offer-section-wrap .h2 {
    font-size: 26px;
  }
}
@media screen and (max-width: 360px) {
  .gp__main-home .offer-section .offer-section-wrap .h2 {
    font-size: 24px;
  }
}
.gp__main-home .offer-section .offer-section-wrap p {
  font-weight: 400;
  font-size: 22px;
  line-height: 150%;
  color: #1a1a1a;
  margin-top: 20px;
}
@media screen and (max-width: 768px) {
  .gp__main-home .offer-section .offer-section-wrap p {
    font-size: 20px;
  }
}
.gp__main-home .comments-section {
  padding: 60px 0;
}
.gp__main-home .comments-section .h2 {
  font-weight: 700;
  font-size: 46px;
  line-height: 120%;
  text-align: center;
  color: #1a1a1a;
}
@media screen and (max-width: 768px) {
  .gp__main-home .comments-section .h2 {
    margin: 0 auto;
    font-size: 36px;
  }
}
@media screen and (max-width: 480px) {
  .gp__main-home .comments-section .h2 {
    font-size: 26px;
  }
}
@media screen and (max-width: 360px) {
  .gp__main-home .comments-section .h2 {
    font-size: 24px;
  }
}
.gp__main-home .comments-section .icons-box {
  display: flex;
  column-gap: 16px;
  text-align: center;
  justify-content: center;
  margin-top: 30px;
}
@media screen and (max-width: 480px) {
  .gp__main-home .comments-section .icons-box {
    flex-direction: column;
    row-gap: 16px;
    align-items: center;
  }
  .gp__main-home .comments-section .icons-box img:last-child {
    width: 179.83px;
    height: 59.5px;
  }
  .gp__main-home .comments-section .icons-box img:first-child {
    width: 165px;
    height: 42px;
  }
}
.gp__main-home .comments-section .comments-wrap {
  max-width: 925px;
  margin: 62px auto 0 auto;
}
.gp__main-home .comments-section .comments-wrap .comment {
  background: #ffffff;
  box-shadow: 0px 0px 14px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
  padding: 30px 20px;
  margin-bottom: 25px;
}
.gp__main-home .comments-section .comments-wrap .comment .people {
  display: grid;
  grid-template-columns: 40px 1fr;
  column-gap: 20px;
  margin-bottom: 14px;
}
.gp__main-home .comments-section .comments-wrap .comment .people .img-box {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: #e0f9f0;
  overflow: hidden;
}
.gp__main-home .comments-section .comments-wrap .comment .people .img-box span {
  color: #3b7c27;
  text-align: center;
  font-size: 18px;
  font-weight: 500;
}
.gp__main-home .comments-section .comments-wrap .comment .people p {
  font-weight: 400;
  font-size: 16px;
  line-height: 100%;
  color: #1a1a1a;
  margin-bottom: 5px;
}
.gp__main-home .comments-section .comments-wrap .comment h5 {
  font-weight: 700;
  font-size: 15.3px;
  line-height: 23px;
  color: #1a1a1a;
  margin-bottom: 6px;
  margin-top: 14px;
}
.gp__main-home .comments-section .comments-wrap .comment p {
  color: #1A1A1A;
  font-family: Ubuntu;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 125%;
}

.mobile__btn-wrap {
  padding: 10px 0;
  background: rgba(0, 0, 0, 0.6);
  justify-content: center;
  align-items: center;
  z-index: 10;
}
.mobile__btn-wrap .btn {
  display: block;
  text-align: center;
  text-decoration: none;
  padding: 14px 0;
  width: 100%;
  max-width: 235px;
  font-size: 16px;
  line-height: 1;
  color: #fff;
  font-weight: 700;
  background: #85C32F;
  border-radius: 7px;
}
@media screen and (max-width: 480px) {
  .mobile__btn-wrap {
    display: flex !important;
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
  }
}

.questions {
  max-width: 900px;
  display: grid;
  row-gap: 20px;
  margin: 0 auto;
  position: relative;
}
.questions .wrap-step-bar {
  display: grid;
  grid-template-columns: 1fr 1fr;
  position: absolute;
  z-index: 1;
  width: 100%;
  top: 35px;
}
@media screen and (max-width: 680px) {
  .questions .wrap-step-bar {
    grid-template-columns: 1fr;
    top: 220px;
  }
}
@media screen and (max-width: 479px) {
  .questions .wrap-step-bar {
    top: 140px;
  }
}
.questions .wrap-step-bar .bar-line {
  padding: 0 20px;
}
@media screen and (max-width: 680px) {
  .questions .wrap-step-bar.bottom-bar {
    top: 220px;
  }
}
@media screen and (max-width: 479px) {
  .questions .wrap-step-bar.bottom-bar {
    top: 210px;
  }
}
@media screen and (max-width: 680px) {
  .questions .wrap-step-bar.bottom-bar-two {
    top: 220px;
  }
}
@media screen and (max-width: 479px) {
  .questions .wrap-step-bar.bottom-bar-two {
    top: 210px;
  }
}
.questions .steps__bar {
  width: 100%;
  padding: 0;
  margin: 0;
  height: 7px;
  background: rgba(191, 191, 191, 0.2784313725) !important;
  flex-grow: 1;
  box-shadow: none !important;
  border-radius: 36px;
  display: flex;
  align-items: center;
  position: relative;
}
.questions .steps__bar_line {
  -webkit-box-shadow: none;
  box-shadow: none;
  border: none;
  height: 7px;
  border-radius: 36px;
  transition: all 0.5s;
  background: #85C32F;
}
.questions .item {
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  min-height: 380px;
  height: 100%;
}
@media screen and (max-width: 680px) {
  .questions .item {
    grid-template-columns: 1fr;
    min-height: auto;
    height: auto;
  }
}
.questions .item .col.list-lead-info {
  background: white;
  padding: 20px 31px;
  width: 100%;
  height: 100%;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
}
@media screen and (max-width: 680px) {
  .questions .item .col.list-lead-info {
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    padding: 5px 15px 0 15px;
  }
}
@media screen and (max-width: 680px) {
  .questions .item .col.list-lead-info.first {
    height: 200px;
    overflow: scroll;
  }
}
@media screen and (max-width: 479px) {
  .questions .item .col.list-lead-info.first {
    height: 190px;
  }
}
@media screen and (max-width: 680px) {
  .questions .item .col.list-lead-info.second {
    height: 200px;
    overflow: scroll;
  }
}
@media screen and (max-width: 479px) {
  .questions .item .col.list-lead-info.second {
    height: 190px;
  }
}
.questions .item .col.list-lead-info .info .header-title {
  color: #4F4F4F;
  font-family: "Ubuntu", sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 115%;
  border-bottom: 1px solid #4F4F4F;
}
.questions .item .col.list-lead-info .info ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}
.questions .item .col.list-lead-info .info ul li {
  display: grid;
  grid-template-columns: 250px 1fr;
  padding: 30px 0 17px 0;
  border-bottom: 1px dashed #828282;
  column-gap: 15px;
  align-items: center;
}
@media screen and (max-width: 680px) {
  .questions .item .col.list-lead-info .info ul li {
    padding: 12px 0;
  }
}
.questions .item .col.list-lead-info .info ul li.sm-inner {
  padding: 12px 0 17px 0;
}
@media screen and (max-width: 680px) {
  .questions .item .col.list-lead-info .info ul li.sm-inner {
    padding: 12px 0;
  }
}
@media screen and (max-width: 860px) {
  .questions .item .col.list-lead-info .info ul li {
    grid-template-columns: 160px 1fr;
  }
}
.questions .item .col.list-lead-info .info ul li span {
  color: #A4A4A4;
  font-family: "Ubuntu", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  display: block;
}
@media screen and (max-width: 680px) {
  .questions .item .col.list-lead-info .info ul li span {
    font-size: 14px;
  }
}
.questions .item .col.preview {
  overflow: hidden;
  background: white;
  width: 100%;
  height: 100%;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  position: relative;
}
@media screen and (max-width: 680px) {
  .questions .item .col.preview {
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    height: 200px;
  }
}
@media screen and (max-width: 479px) {
  .questions .item .col.preview {
    height: 120px;
  }
}
.questions .item .col.preview img {
  height: 100%;
  width: 100%;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  object-fit: cover;
}
@media screen and (max-width: 680px) {
  .questions .item .col.preview img {
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
}
.questions .item .col.preview #map:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  background: rgba(247, 247, 247, 0.41);
  border-radius: 4px;
  z-index: 8;
}
.questions .item .col.preview #map:after {
  content: "?";
  position: absolute;
  width: 115px;
  height: 115px;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  font-style: normal;
  font-weight: 400;
  font-size: 50px;
  line-height: 32px;
  color: rgba(135, 135, 135, 0.9);
  border-radius: 50%;
  border: 5px solid rgba(135, 135, 135, 0.9);
  z-index: 9;
}
@media screen and (max-width: 680px) {
  .questions .item .col.preview #map:after {
    width: 70px;
    height: 70px;
    top: 50%;
    transform: translateY(-50%);
    margin: 0 auto 0px 20px;
  }
}
.questions .item .col.preview #map2:after {
  content: "";
  position: absolute;
  width: 190px;
  height: 190px;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(87, 168, 70, 0.2);
  border-radius: 50%;
  border: 3px solid rgba(15, 169, 88, 0.8);
  z-index: 9;
}
@media screen and (max-width: 680px) {
  .questions .item .col.preview #map2:after {
    width: 70px;
    height: 70px;
  }
}
.questions .item .col.preview #map3:after {
  content: "";
  position: absolute;
  width: 50px;
  height: 50px;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  background: url("../../img/dist/gutter-protection-new/marker.png") center center/cover;
  z-index: 9;
}
.questions .item .col.preview #map,
.questions .item .col.preview #map2,
.questions .item .col.preview #map3 {
  display: block;
  max-width: 100%;
  max-height: 100%;
  width: 100%;
  height: 100%;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  pointer-events: none;
}
@media screen and (max-width: 680px) {
  .questions .item .col.preview #map,
  .questions .item .col.preview #map2,
  .questions .item .col.preview #map3 {
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
}
.questions .item .col.form-section {
  background: rgba(255, 255, 255, 0.96);
  padding: 70px 20px 20px 20px;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  backdrop-filter: blur(19px);
  height: 100%;
}
@media screen and (max-width: 680px) {
  .questions .item .col.form-section {
    border-top-right-radius: 0;
    border-top-left-radius: 0;
    border-bottom-right-radius: 20px;
    border-bottom-left-radius: 20px;
    min-height: 450px;
    padding: 37px 20px 20px 20px;
  }
}
.questions .item .col.form-section .bottom-text {
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 7px;
  margin-top: 10px;
}
@media screen and (max-width: 680px) {
  .questions .item .col.form-section .bottom-text {
    margin-top: 17px;
  }
}
.questions .item .col.form-section .bottom-text span {
  color: #828282;
  text-align: center;
  font-size: 14px;
  font-weight: 400;
  display: block;
}
.questions .item .col.form-section .title {
  font-family: "Ubuntu", sans-serif;
  font-size: 22px;
  font-weight: 700;
  line-height: 25.3px;
  text-align: center;
  display: block;
  margin-bottom: 10px;
}
@media screen and (max-width: 680px) {
  .questions .item .col.form-section .title {
    font-size: 22px;
  }
}
.questions .item .col.form-section .inputs.grid-3 {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
}
@media screen and (max-width: 680px) {
  .questions .item .col.form-section .inputs.grid-3 {
    gap: 5px;
    display: flex;
    flex-direction: column;
  }
}
.questions .item .col.form-section .checkbox-btn,
.questions .item .col.form-section .radio-btn {
  position: absolute;
  opacity: 0;
}
.questions .item .col.form-section .steps__roofing_form_checkbox label {
  height: 100% !important;
  box-shadow: none;
  margin-bottom: 0;
  cursor: pointer;
  padding: 11px 20px;
  text-align: left;
  background: #FFF;
  font-family: "Ubuntu", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  color: #333333;
  border: 2px solid #D9D9D9;
  border-radius: 8px;
  display: flex;
  align-items: center;
}
.questions .item .col.form-section .steps__roofing_form_checkbox .checkbox-btn:checked + label {
  background: #85C32F;
  border: 2px solid #85C32F;
  color: #FFF;
  font-weight: 700;
}
.questions .item .steps__buttons {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.questions .item .steps__buttons .btn-prev {
  position: absolute;
  bottom: 20px;
}
.questions .item .steps__buttons .btn-prev {
  margin: 20% 0 0 0;
  width: 100% !important;
  max-width: 125px;
  padding: 0;
  background: 0 0;
  border: none;
  display: block;
  margin: 15px auto 0 auto;
  text-align: center;
  font-size: 16px;
  font-weight: 500;
  line-height: 150%;
  color: #828282;
  text-transform: uppercase;
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 6px;
}
.questions .item .steps__buttons .btn-prev span {
  display: block;
}

.banner-map {
  bottom: auto;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  max-width: 350px;
  top: 20px;
  position: absolute;
  color: #fff;
  font-family: "Ubuntu", sans-serif;
  font-size: 17px;
  font-style: normal;
  font-weight: 600;
  line-height: 130%;
  border-radius: 15px;
  padding: 7px 15px;
  z-index: 10;
  background: #85C32F;
}
@media screen and (max-width: 767px) {
  .banner-map {
    max-width: 70%;
    text-align: center;
  }
}
@media screen and (max-width: 680px) {
  .banner-map {
    max-width: 250px;
    right: 20px;
    left: auto;
    transform: translate(0, -50%);
    top: 50%;
  }
}
@media screen and (max-width: 479px) {
  .banner-map {
    font-size: 14px;
    line-height: 16px;
    max-width: 200px;
  }
}